.call-to-action-digital {
    &__container {
        $url-fondo-suscripcion-digital: $url-images + "img-banner-cta-eltiempo-digital.jpg";
        $url-fondo-suscripcion-digital-movil: $url-images + "mobile/"+ "img-banner-cta-eltiempo-digital-movil.jpg";
        width: 100%;
        height: 250px;
        position: relative;

        @media (max-width: $mobile-breakpoint) {
            background: url($url-fondo-suscripcion-digital-movil) no-repeat 0 0 / cover transparent;
        }

        @media (min-width: $tablet-breakpoint) {
            background: url($url-fondo-suscripcion-digital) no-repeat right top / cover transparent;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
        }
        &::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.68) 22%, transparent 78%);
            @media (max-width: $mobile-breakpoint) {
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.68) 22%, transparent 78%);
            }
        }
        &::after {
            top: 60px;
            left: 78px;
        }
    }
    &__textos {
        $url-fondo-logo-suscripcion: $url-images + "img-logos-cta-eltiempo-digital.png";
        @include dibujaSprite(282, $sprite-logos-vector, 127, 0, 0);
        background-size: auto 242.36px;
        -ms-background-position-x: -380px;
        position: absolute;
        top: 50%;
        left: 78px;
        transform: translateY(-50%);
        margin: 0;
        padding: 45px 0 0 136px;
        width: auto;
        height: auto;
        @media (max-width: $mobile-breakpoint) {
            padding: 25px;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            transform: none;
            text-align: center;
            padding-top: 40px;
            background-position: 50% -215px;
            background-size: 280px auto;
        }
        @media (min-width: $tablet-breakpoint) and (max-width: $medium-breakpoint) {
            padding: 40px 0 0 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-position: 50% -210px;
        }
    }
    &__descripcion {
        font-family: $fte-oswald-bold, 'Arial Narrow', Arial, Helvetica, sans-serif;
        @include rem(25);
        color: $blancoff;
        line-height: (28/25);
        margin: 0;
        padding: 0;
        @media (max-width: $mobile-breakpoint) {
            @include rem(16);
            color: #FFFFFF;
            text-align: center;
            line-height: (18/16);
            text-shadow: 0 2px 4px rgba(0, 0, 0, .5);
        }
    }
    &__enlace {
        display: block;
        float: left;
        @include rem(14);
        color: $blancoff;
        line-height: (19/14);
        border: 2px solid $blancoff;
        padding: 7px 6px 7px;
        text-transform: uppercase;
        margin: 20px 0 0;
        @media (max-width: $mobile-breakpoint) {
            float: none;
            display: inline-block;
            width: 254px;
            padding: 6px;
            margin: 16px 0 0;
        }
        @media (min-width: $tablet-breakpoint) and (max-width: $medium-breakpoint) {
            float: none;
            display: inline-block;
        }
    }
}