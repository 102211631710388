.revistas {
	display: block;
	float: left;
	width: 100%;

	&__donjuan {
		&-cover {
			$url-donjuan: $url-images + "donjuan-cover.png";
			background: url($url-donjuan );
		}

		&-logo {
			@include dibujaSprite(15, $sprite-logos, 157, -339, 0);
		}
	}

	&__carrusel {
		&-cover {
			$url-carrusel: $url-images + "carrusel_revista-2.jpg";
			background: url($url-carrusel);
		}

		&-logo {
			background: url(../images/logocarrusel.png) no-repeat;
			background-position: center;
			width: 131px;
			height: 24px;
			image-rendering: -webkit-optimize-contrast;
		}
	}

	&__habitar {
		&-cover {
			$url-habitar: $url-images + "habitar-cover-2.jpg";
			background: url($url-habitar );
		}

		&-logo {
			@include dibujaSprite(40, $sprite-logos, 119, -339, -18);
		}
	}

	&__alo {
		&-cover {
			$url-alo: $url-images + "alo-cover-3.png";
			background: url($url-alo) no-repeat center;
			background-size: contain;
		}

		&-logo {
			background: url($url-images + "logo-alo-1.png") no-repeat center;
			background-size: contain;
			width: 166px;
			height: 74px;
		}
	}

	&__bocas {
		&-cover {
			$url-bocas: $url-images + "bocas-cover-3.png";
			background: url($url-bocas) no-repeat center;
			background-size: contain;
		}

		&-logo {
			background: url($url-images + "logo-bocas.png") no-repeat center;
			background-size: contain;
			width: 166px;
			height: 66px;
		}
	}

	&__portafolio {
		&-cover {
			$url-revistas: $url-images + "portafolio-cover.png";
			background: url($url-revistas) no-repeat center;
			background-size: contain;
		}

		&-logo {
			background: url($url-images + "logo-portafolio.png") no-repeat center;
			background-size: contain;
			width: 166px;
			height: 66px;
		}
	}

	&__hola {
		&-cover {
			$url-hola: $url-images + "hola-cover.jpg";
			background: url($url-hola );
		}

		&-logo {
			@include dibujaSprite(64, $sprite-logos, 131, -4, -47);
			$url-holaLogo: $url-images + "hola_colombia_red.jpg";
			background: url($url-holaLogo );
		}
	}

	&__cover {
		background-position: center;
		background-repeat: no-repeat;
		height: 250px;
		margin: 0 auto;
		width: 205px;
	}

	&__logo-box {
		height: 84px;
		position: relative;
	}

	&__logo {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__box {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 5%;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			padding: 0;
			width: 260px;
		}
	}

	&__lead {
		@include rem(13);
		display: table-cell;
		font-family: $revistasLeadFuente;
		height: 95px;
		margin-bottom: 20px;
		vertical-align: middle;
	}

	&__controles {
		transform: translateY(-382px);

		@media (min-width: $mobile-breakpoint) {
			transform: translateY(-332px);
		}

		&-prev {
			float: left;
		}

		&-next {
			float: right;
			transform: scale(-1);
		}

		a {
			@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
			background-color: transparent;
			border: none;
			cursor: pointer;

			&:hover {
				@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
				background-color: transparent;
			}

		}
	}

	&__swipe,
	&-carousel-box {
		background: $revistasFondo;
		padding-top: 60px;
	}

}

#swipeRevistas .revista__btn {
	height: 48px;
	padding: 3% 0;
	border: none;
	background: linear-gradient(to right, #7b2bda, #2e1ad3);
	border-radius: 10px;
}