.cursos-cortos {
	display: block;
	float: left;
	width: 100%;

	&__caja {
		$url-cursos: $url-images + "mobile/banner_cursos_cortos_et_2.png";
		background: url($url-cursos );
		background-position: 29%;
		background-size: cover;
		height: 330px;
		padding: 24% 5% 4% 5%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media (min-width: $mobile-breakpoint) {
			$url-cursos: $url-images + "banner_cursos_cortos_et_2.png";
			background: url($url-cursos );
			height: 385px;
			padding: 0 0 0 8%;
		}

		p {
			@include rem(19);
			font-family: $cajaFuenteSpan;
			margin: 0 0 5px 0;

			@media (min-width: $mobile-breakpoint) {
				@include rem(27);
			}
		}
		&:after{
			opacity: 0.6;
		}
	}

	&__titulo  {
    	padding-bottom: 20px;
		font-size: 2.563rem;
		width: 90%;
		&::before {
			padding-bottom: 4%;
			width: 32%;
		}
		@media (min-width: $mobile-breakpoint){
			width: 100%;
		}
	}

	&__link {
		background: linear-gradient(to right,#7b2bda,#2e1ad3);
		border: none;
		height: 45px;
		padding: 1% 0;
		border-radius: 10px;
		margin-bottom: 3%;
		@media (max-width: $mobile-breakpoint){
			padding: 3% 0;
		}
	}

}
