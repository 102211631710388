.colecciones {
	// box-sizing: border-box;
	// display: inline-block;
	// width: 100%;



	// &__caja {
	// 	$url-colecciones: $url-images + "mobile/banner_colecciones_et.jpg";
	// 	background: url($url-colecciones );
	// 	background-size: cover;
	// 	height: 337px;
	// }

	// &__link {
	// 	width: 100%;

	// 	@media (min-width: $mobile-breakpoint) {
	// 		width: 320px;
	// 	}
	// }

	// @media (min-width: $mobile-breakpoint) {
		float: left;

		// width: 815px;

		&__caja {
			$url-colecciones-mobile: $url-images + "banner_el_tiempo_colecciones2.jpg";
			background: url( $url-colecciones-mobile ) center;
			height: 400px;
			background-size: cover;
			display: flex;
			justify-content: flex-end;
			flex-flow: column;
			padding: 0 8%;
			@media (max-width: $mobile-breakpoint) {
				justify-content: flex-end;
				padding: 0 7%;
				padding-bottom: 10%;
				height: 350px;
			}
		}
	// }
	// @media (min-width: $medium-breakpoint) {
		
		width: 100%;

		// }

		&__link {
			background: linear-gradient(to right,#7b2bda,#2e1ad3);
			border: none;
			height: 45px;
			padding: 1% 0;
			border-radius: 10px;
			margin-bottom: 3%;
			@media (max-width: $mobile-breakpoint){
				padding: 3% 0;
			}
		}

		&__titulo{
			@media (max-width: $mobile-breakpoint) {
				font-size: 2.563rem;
				width: 90%;
			}	  
		}
}

.colecciones__caja:after{
	opacity: 0.6;
}
