@font-face {
	font-family: 'et_icons';
	src: url('../fonts/et_icons/et_icons.eot') format('embedded-opentype'),
	url('../fonts/et_icons/et_icons.woff') format('woff'),
	url('../fonts/et_icons/et_icons.ttf') format('truetype'),
	url('../fonts/et_icons/et_icons.svg') format('svg');
	font-style: normal;
};

@font-face {
	font-family: 'Oswald_medium';
	src:
		url('../fonts/Oswald-Medium.eot') format('embedded-opentype'),
		url('../fonts/Oswald-Medium.woff') format('woff'),
		url('../fonts/Oswald-Medium.ttf') format('truetype')
		,url('../fonts/Oswald-Medium.svg') format('svg')
		;
	font-style: normal;
};

$fte-oswald-medium: 'Oswald_medium', sans-serif;

@font-face {
	font-family: 'Oswald_light';
	src:
		url('../fonts/Oswald-Light.eot') format('embedded-opentype'),
		url('../fonts/Oswald-Light.woff') format('woff'),
		url('../fonts/Oswald-Light.ttf') format('truetype')
		,url('../fonts/Oswald-Light.svg') format('svg')
		;
	font-style: normal;
};

$fte-oswald-light: 'Oswald_light', sans-serif;

@font-face {
	font-family: 'Oswald_bold';
	src:
		url('../fonts/Oswald-Bold.eot') format('embedded-opentype'),
		url('../fonts/Oswald-Bold.woff') format('woff'),
		url('../fonts/Oswald-Bold.ttf') format('truetype')
		,url('../fonts/Oswald-Bold.svg') format('svg')
		;
	font-style: normal;
};

$fte-oswald-bold: 'Oswald_bold', sans-serif;

@font-face {
	font-family: 'Oswald_regular';
	src:
		url('../fonts/Oswald-Regular.eot') format('embedded-opentype'),
		url('../fonts/Oswald-Regular.woff') format('woff'),
		url('../fonts/Oswald-Regular.ttf') format('truetype')
		,url('../fonts/Oswald-Regular.svg') format('svg')
		;
	font-style: normal;
};

$fte-oswald-regular: 'Oswald_regular', sans-serif;


@font-face {
	font-family: 'Hind_bold';
	src:
		url('../fonts/Hind-Bold.ot') format('embedded-opentype'),
		url('../fonts/Hind-Bold.woff') format('woff'),
		url('../fonts/Hind-Bold.ttf') format('truetype')
		,url('../fonts/Hind-Bold.svg') format('svg')
		;
	font-style: normal;
};

$fte-hind-bold: 'Hind_bold', sans-serif;

@font-face {
	font-family: 'Hind_light';
	src:
		url('../fonts/Hind-Light.eot') format('embedded-opentype'),
		url('../fonts/Hind-Light.woff') format('woff'),
		url('../fonts/Hind-Light.ttf') format('truetype')
		,url('../fonts/Hind-Light.svg') format('svg')
		;
	font-style: normal;
};

$fte-hind-light: 'Hind_light', sans-serif;

@font-face {
	font-family: 'Hind_medium';
	src:
		url('../fonts/Hind-Medium.eot') format('embedded-opentype'),
		url('../fonts/Hind-Medium.woff') format('woff'),
		url('../fonts/Hind-Medium.ttf') format('truetype')
		,url('../fonts/Hind-Medium.svg') format('svg')
		;
	font-style: normal;
};

$fte-hind-medium: 'Hind_medium', sans-serif;

@font-face {
	font-family: 'Hind_regular';
	src:
		url('../fonts/Hind-Regular.eot') format('embedded-opentype'),
		url('../fonts/Hind-Regular.woff') format('woff'),
		url('../fonts/Hind-Regular.ttf') format('truetype')
		,url('../fonts/Hind-Regular.svg') format('svg')
		;
	font-style: normal;
};

$fte-hind-regular: 'Hind_regular', sans-serif;
