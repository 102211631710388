.footer {
	@include containerDesktop();
	background: $footerBg;
	float: left;
	width: 100%;

	@media (min-width: $medium-breakpoint) {
		width: 100%;
		min-width: 1170px;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 989px){
			display: block;
		}

	}

	&__sic {
		position: relative;
		width: 97px;
		height: 27px;
		@media (max-width: 989px){
			margin: 0 auto 13px;
		}

		&::before {
			content: '';
			width: 1px;
			height: 20px;
			background: #434343;
			position: absolute;
			left: -20px;
			@media (max-width: 989px){
				content: none;
			}
		}
		img {
			width: 97px;
			height: 27px;
			@media (max-width: 989px){
				width: 97px;
				height: 27px;
			}
		}
  	}

	&__copyright {
		@include rem(10);
		color: $footerColor;
		font-family: $footerFuente;
		padding: 20px 40px;

		@media (min-width: $medium-breakpoint) {
			@include rem(13);
			padding: 15px 40px;
		}
	}
}
