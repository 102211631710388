.libros {
	display: block;
  float: left;
  width: 100%;

	&__caja {
		$url-libros: $url-images + "banner_intermedio_editores_2.jpg";
		background: url($url-libros );
	  	background-position: 29%;
		background-size: cover;
		height: 340px;
		padding: 25% 5% 4% 5%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media (min-width: $mobile-breakpoint) {
			height: 340px;
			padding: 0 0 0 8%;

		}
		&:after{
			opacity: 0.6;
		}
	}

	&__titulo {
		font-size: 2.563rem;
		width: 90%;
		@media (min-width: $mobile-breakpoint) {
			width: 580px;
		}

		&::before {
			width: 14%;
		}
	}

	&__link {
		background: linear-gradient(to right,#7b2bda,#2e1ad3);
		border: none;
		height: 45px;
		padding: 1% 0;
		border-radius: 10px;
		margin-bottom: 3%;
		@media (max-width: $mobile-breakpoint){
			padding: 3% 0;
		}
	}

	::after{
		opacity: 0.6;
	}
}
