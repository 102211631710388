//paleta de colores
$alicebluef8: #f8f3ff;
$azul00: #006aaa;
$blancoff: #fff;
$gris35: #353535;
$gris51: #515151;
$gris7b: #7b7b7b;
$grisd8: #d8d8d8;
$grisec: #ececec;
$naranjaff: #ff8e00;
$naranjaf9: #f96339;
$negro20: #202020;
$negro: #000;


//colores de elementos - semanticos
$headerBg: $gris7b;

$botonColor: $blancoff;
$botonBg1: $azul00;
$botonHv1: $naranjaff;
$botonBg2: $naranjaf9;
$botonBorde: $blancoff;
$botonFuente: $fte-oswald-medium;

$tituloColor: $azul00;
$tituloFuente: $fte-oswald-light;

$footerBg: $negro20;
$footerColor: $alicebluef8;
$footerFuente: $fte-oswald-medium;

$leadColor: $blancoff;
$leadFuente: $fte-oswald-bold;

$cajaFuenteB: $fte-oswald-medium;
$cajaFuenteSpan: $fte-oswald-light;
$cajaColor: $blancoff;
$cajaBordeTop: $blancoff;

$renovacionLead: $fte-hind-regular;
$renovacionLeadM: $fte-hind-medium;
$renovacionLeadB: $fte-hind-bold;
$renovacionColor: $blancoff;
$renovacionTelefono: $fte-oswald-bold;


$vivamosBg: $gris51;
$vivamosBorde: $grisd8;
$vivamosColor: $blancoff;
$vivamosFuente: $fte-hind-medium;
$vivamosTexto: $fte-hind-light;

$vivamosTitulosFuente: $fte-oswald-medium;
$vivamosMensajeFuente: $fte-hind-medium;

$bottomFuente: $fte-oswald-bold;
$bottomFuenteL: $fte-oswald-regular;
$bottomColor: $gris35;


$revistasLeadFuente: $fte-oswald-medium;
$revistasLeadColr: $negro20;
$revistasFondo: $grisec;

//variables de media queries
$mobile-breakpoint: 768px;
$tablet-breakpoint: 767px;
$medium-breakpoint: 990px;

//rutas
$prefix: "../";
$url-images: $prefix + "images/";

$sprite-logos: $url-images + "sprite.png";
$alo-logo : $url-images + "logo-alo.png";
$sprite-logos-vector: $url-images + "sprite-vector-tienda.svg";
