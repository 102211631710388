.header {
	@include containerDesktop();
	background: $headerBg;
	height: 60px;

	@media (min-width: $medium-breakpoint) {
		height: 88px;
		min-width: 1170px;
	}

	//solo mobile
	&__menu {
		@include dibujaSprite(24, $sprite-logos, 24, -46, -80);
		float: left;
		margin: 15px 13px 15px 13px;
	}

	&__logo-et {
		//@include dibujaSprite(23, $sprite-logos-vector, 127, -20, -84);
		background: url($url-images + "logo-suscripciones.png");
		background-size: auto;
		margin: 0 auto;
		background-repeat: no-repeat;
		height: 40px;
		width: 240px;

		@media (min-width: $medium-breakpoint) {
			float: left;
			background-size: 100%;
			background-position: 0;
			width: 240px;
			height: 44px;
			background-repeat: no-repeat;
		}
	}


	//solo desktop
	&__boton-suscribete {
	    background: $botonBg2;
	    float: right;
	    @media (min-width: $medium-breakpoint) {
	        margin-top: 4px;
	    }
	}
}
