.bottom {
	display: block;
	float: left;
	width: 100%;
	padding: 20px 0;

	@media (min-width: $mobile-breakpoint) {
		padding: 40px 0;
	}

	&__logo-naranja {
		//@include dibujaSprite(60, $sprite-logos, 175, -94, -317);
		//$url-naranja: $url-images + "logo-suscripciones-naranja.png";
		background: url($url-images + "logo-suscripciones-naranja.png");
		background-repeat: no-repeat;
		margin: 20px auto;
		height: 44px;
		background-size: 100%;
		width: 285px;
		background-position-x: -11px;

		@media (min-width: $mobile-breakpoint) {
			background: url($url-images + "logo-suscripciones-naranja.png" );
			//@include dibujaSprite(60, $sprite-logos, 440, -94, -317);
			background-size: 100%;
			height: 44px;
			background-repeat: no-repeat;
			margin: 0;
			background-position-x: -12px;
		}
	}

	&__1 {
		float: left;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			width: 416px;
		}

	}

	&__2 {
		float: left;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			margin-left: 150px;
			width: 600px;
		}
	}

	&__direccion {
		@include rem(11);
		color: $bottomColor;
		float: left;
		font-family: $bottomFuente;
		line-height: 1;
		padding-top: 10px;
		text-align: center;
    	width: 100%;

		p {
			a {
				color: #353535;
			}
		}

		@media (min-width: $mobile-breakpoint) {
			@include rem(13);
			text-align: left;
		}
	}

	&__telefonoBox {
		box-sizing: border-box;
		float: left;
		padding-left: 40px;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			padding-left: 0;
			width: 300px;
		}

		p {
			@include rem(13);
			color: $bottomColor;
			font-family: $bottomFuente;
			line-height: 0.8;
	    padding-left: 105px;
			text-align: left;

			@media (min-width: $mobile-breakpoint) {
    		padding-left: 0;
				text-align: center;
			}
		}

		&::before {
			@include dibujaSprite(60, $sprite-logos, 60, -455, -62);
			border-right: 1px solid $bottomColor;
			content: " ";
			display: block;
			float: left;
			margin: 20px 0 20px 15px;
			margin-top: 20px;
			padding: 0px 20px 0 0;
			position: relative;
			transform: scale(0.6);

			@media (min-width: $mobile-breakpoint) {
				transform: scale(1);
			}
		}

		.bottom__telefono {
			@include rem(17);
		}

		.bottom__horario {
			font-family: $bottomFuenteL;
		}
	}


}
