.popup {
    position: fixed;
    bottom: 0;
    // top:0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    border-bottom: 2px solid #FF6600;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    z-index: 1000;
}

.popup-content {
    max-width: 1200px;
    margin: 0 auto;
}

.popup-content p {
    font-family: sans-serif;
    margin: 0;
    text-align: left;
    margin-top: 26px;
}

.popup-content a {
    color: blue;
}

.popup-content button {
    background-color: #FF6600;
    color: white;
    font-weight: 600;
    font-size: 18px;
    border: none;
    text-transform: uppercase;
    padding: 10px 20px;
    margin-bottom: 17px;
    border-radius: 5px;
    cursor: pointer;
    width: 154px;
    height: 44px;
}

@media screen and (max-width: 600px) {
    .popup-content {
        padding: 0 10px;
    }

    .popup-content p {
        font-size: 12px;
        margin: 10px 6px;
        text-align: left;
    }

    .popup-content button {
        margin-top: 17px;
        font-size: 12px;
        width: 91px;
        height: 28px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}