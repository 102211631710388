@charset "UTF-8";

@import "common/reset",

"common/fonts",
"common/variables",
"common/mixins",
"common/slider",
"common/carousel", 
"common/icons";

#landing-suscribete {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	position: relative;

	@import "common/general",
	"common/header",
	"common/cta-digital",
	"common/periodicos",
	"common/colecciones",
	"common/renovaciones",
	"common/oferta-destacada",
	"common/libros",
	"common/cursos-cortos",
	"common/club-vivamos",
	"common/revistas",
	"common/bottom",
	"common/footer",
	"common/popupcookies";
}
