.oferta_destacada{
    width: 67%;
    float: left;
    @media (max-width: $mobile-breakpoint) {
        width: 100%;
    }
    &__caja {
        $url-renovaciones: $url-images + "banner12x15_768x400.jpg";
        background: url($url-renovaciones ) no-repeat;
        background-size: cover;
        height: 400px;
        padding: 0;
        display: flex;
        flex-flow: column;
        justify-content: start;
        margin-right: 20px;
    	
        @media (max-width: $mobile-breakpoint) {
            // padding: 0;
            margin: 0;
            align-items: center;
            background-position: 0;
            background-repeat: initial;
        }
        @media (max-width: 390px) {
            background-position: -24px 10px;
            background-size: 190%;
            background-repeat: unset;
        }
        span{
            font-family: "Oswald_bold", sans-serif;
            font-weight: normal;
            line-height: 40px;
            padding-bottom: 0px; 
            //color: #eebd01;          
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
            }
        }
        p {
            margin: 64px 18% 20px;
            text-align: left;
            width: 100%;
            line-height: 33px;
            color: #FFF;
            text-shadow: none;
        }
        b{
            font-family: "Oswald_light", sans-serif;
            padding-right: 0px;
        }
        .btn{            
            width: 210px;
            height: 35px;
            background: #2aa9dc;
            margin: 35px 10%;
            border: none;
            border-radius: 7px;
        }
    }
    &__titulo {
        font-size: 2rem;
        font-family: "Oswald_medium", sans-serif; 
        display: flex;
        flex-flow: column; 
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
            text-align: center;
        }
    }
    &__lead {
        font-size: 30px;
        font-family: "Oswald_bold", sans-serif; 
        display: flex;
        flex-flow: row;
        padding-bottom: 0;
        
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
            text-align: center;
            font-size: 30px;
            padding-top: 10px;
        }
    }
    .precio_oferta_destacada_men{
        font-family: 'Oswald_light', sans-serif;
        font-size: 22px;
        font-weight: 400;
        padding-top: 0px;
        width: 271px;
        color: #FFF;
        text-align: center;
        line-height: 26px;
        margin: 0 auto 8px;
        color: #4a4a4a;
        @media (max-width: $mobile-breakpoint) {
            width: 58%;
            text-align: center;
            font-size: 18px;
        }
        @media (max-width: '390px'){
            width: 75%;
        }
        span{
            font-family: "Oswald_bold", sans-serif;
            font-weight: normal;
            line-height: 28px;
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
            }
        }
    }
    .precio_oferta_destacada_an{
        font-family: 'Oswald_medium', sans-serif;
        font-size: 22px;
        font-weight: 400;
        padding-top: 10px;
        width: 190px;
        color: #FFF;
        text-align: left;
        margin-bottom: 40px;
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
            text-align: center;
        }
    }
    .subtitulo_oferta_destacada{
        text-align: left;
        color: white;
        font-family: 'Oswald_medium', sans-serif;
        font-size: 25px;
        width: 40%;
        margin: 0 10%;
        @media (max-width: $mobile-breakpoint) {
            font-size: 28px;
            width: 100%;
        }
    }
    .caja-w56{
        width: 56%;
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }
}