.club-vivamos {
	display: block;
	float: left;
	max-width: 100%;

	&__fila {
		background: $vivamosBg;
		display: block;
		float: left;
		width: 100%;
	}

	&__fila2 {
		overflow-x: auto;
		padding-bottom: 55px;
	}

	&__logo {
		$url-vivamos: $url-images + "logo-vivamos-graybg.png";
		background: url($url-vivamos );
		background-repeat: no-repeat;
		box-sizing: border-box;
		height: 140px;
		image-rendering: -moz-crisp-edges;
		/* Firefox */
		image-rendering: -o-crisp-edges;
		/* Opera */
		image-rendering: -webkit-optimize-contrast;
		/* Webkit (non-standard naming) */
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
		/* IE (non-standard property) */
		margin: 0 auto;
		position: relative;
		transform: scale(0.7);
		width: 299px;

		@media (min-width: $mobile-breakpoint) {
			float: left;
			margin: 20px 0 0 80px;
			padding: 0 30px 30px 0;
			transform: scale(1);

			&::after {
				background: rgba($vivamosBorde, 0.3);
				content: "";
				display: block;
				height: 80px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
			}
		}


	}

	&__lead {
		@include rem(15);
		box-sizing: border-box;
		color: $vivamosColor;
		float: left;
		font-family: $vivamosFuente;
		padding: 30px 60px;
		text-align: center;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			@include rem(19);
			padding: 55px 75px;
			text-align: left;
			width: 750px;
		}

	}

	&__texto {
		@include rem(15);
		color: $vivamosColor;
		font-family: $vivamosTexto;
		text-align: center;
	}

	&__servicios {
		display: inline-block;
		text-align: center;
		width: 1028px;
	}

	&__servicio {
		cursor: pointer; 
		float: left;
		margin: 0 20px;
		position: relative;

		&::after {
			background: rgba($vivamosBorde, 0.3);
			content: "";
			height: 80px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			right: -21px;
		}

		&:last-of-type::after {
			display: none;
		}

	}

	&__icono {
		@include dibujaSprite(100, $sprite-logos, 130, -256, -123);
		margin: 10px 0;
	}

	&__turismo {
		.club-vivamos__icono {
			@include dibujaSprite(100, $sprite-logos, 130, -256, -221);
		}
	}

	&__ropa {
		.club-vivamos__icono {
			@include dibujaSprite(100, $sprite-logos, 130, 3, -123);
		}
	}

	&__salud {
		.club-vivamos__icono {
			@include dibujaSprite(100, $sprite-logos, 130, -386, -221);
		}
	}

	&__entretenimiento {
		.club-vivamos__icono {
			@include dibujaSprite(100, $sprite-logos, 130, -388, -123);
		}
	}

	&__hogar {
		.club-vivamos__icono {
			@include dibujaSprite(100, $sprite-logos, 130, -127, -123);
		}
	}

	&__renovaciones {
		$url-renovaciones: $url-images + "banner_inferior_renovaciones.jpg";
		background: url($url-renovaciones );
		background-size: cover;
		background-position: top right;
		float: left;
		height: 300px;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			height: 280px;
			width: 50%;
		}
	}

	&__puntos-venta {
		$url-puntosventa: $url-images + "new_image_puntos_ventas.jpg";
		background: url($url-puntosventa );
		background-size: cover;
		background-position: top right;
		float: left;
		height: 300px;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			height: 280px;
			width: 50%;
		}
	}

	&__bottom-box {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 125px 5% 0 5%;
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			padding: 125px 0 0 0;

			width: 280px;
		}
	}

	&__titulo {
		@include rem(27);
		font-family: $vivamosTitulosFuente;
		color: $vivamosColor;
		text-align: left;
	}

	&__mensaje {
		@include rem(13);
		font-family: $vivamosMensajeFuente;
		color: $vivamosColor;
		margin: 10px 0 20px 0;
		text-align: left;
	}
	&__submensaje {
		@include rem(13);
		font-family: $vivamosMensajeFuente;
		color: $vivamosColor;
		margin: -10px 0 20px 0;
		text-align: left;

		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	&__link2 {
		background: $botonBg2;
	}
}
