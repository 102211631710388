.periodicos {
	display: inline-block;
	width: 100%;

	&__desktop {
		.periodicos__eltiempo {
			$url-et: $url-images + "eltiempo2.jpg";
			background: url($url-et );
			float: left;
			height: 300px;
			position: relative;
			width: 48%;
			margin-right: 4%;

			.periodicos__logo {
				@include dibujaSprite(27, $sprite-logos, 146, -169, -2);
				display: none;
				text-align: center;
			}
		}

		.periodicos__portafolio {
			$url-portafolio: $url-images + "portafolio2.jpg";
			background: url($url-portafolio );
			float: left;
			height: 300px;
			position: relative;
			width: 48%;

			.periodicos__logo {
				@include dibujaSprite(28, $sprite-logos, 146, -15, -2);
				display: none;
				text-align: center;
			}
		}
	}

	&__mobile {
    margin-bottom: -45px;

		.periodicos__eltiempo {
			$url-et: $url-images + "mobile/eltiempo2.jpg";
			background: url($url-et );
			background-size: cover;
			height: 348px;
			width: 100%;

			.periodicos__logo {
				@include dibujaSprite(27, $sprite-logos, 146, -169, -2);
				display: inline-block;
				text-align: center;
				display: none;
			}
		}

		.periodicos__portafolio {
			$url-portafolio: $url-images + "mobile/portafolio2.jpg";
			background: url($url-portafolio );
			background-size: cover;
			height: 348px;
			width: 100%;

			.periodicos__logo {
				@include dibujaSprite(28, $sprite-logos, 146, -15, -2);
				display: inline-block;
				text-align: center;
				display: none;
			}
		}
	}
	&__infobox {
		bottom: 20px;
		height: auto;
		left: 10%;
		top: 30%;
		margin: 0 auto;
		position: absolute;
		text-align: center;
		//transform: translateX(-50%);
		width: 250px;
	}


	&__infobox-mobile {
		box-sizing: border-box;
		bottom: 40px;
		margin: 0 auto;
		left: 50%;
		padding: 0 5%;
		position: absolute;
		transform: translateX(-50%);
		width: 100%;

		@media (min-width: $mobile-breakpoint) {
			padding: 0;
			width: 178px;
		}
	}


	&__link {
		margin: 0 auto;
		width: 100%;
		background: linear-gradient(160deg,#2e1ad3 50%,#2a9eb5);
		border: none;
		border-radius: 10px;
		height: 45px;
		padding: 3% 0px;
		top: 50%;

		@media (min-width: $mobile-breakpoint) {
			width: 300px;
			position: relative;
			left: 30%;
			height: 45px;
			border-radius: 10px;
			padding: 3% 0;
			background: linear-gradient(160deg,#2e1ad3 50%,#2a9eb5);
			border: none;
		}
	}

	&__lead {
		@include rem(15);
		color: #000;
		font-family: $leadFuente;
		margin: 0 0 10%;
		padding: 10px 0;
		width: 155px;
		height: 44px;
		padding-left: 130%;
		@media (max-width: $mobile-breakpoint){
			color: #000;
			margin: 0 5%;
		}
	}

	&__controles {
		transform: translateY(-215px);

		&-prev {
			float: left;
		}

		&-next {
			float: right;
			transform: scale(-1);
		}

		a {
			@include dibujaSprite(25, $sprite-logos, 15, -33, -79);
			border: none;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				@include dibujaSprite(25, $sprite-logos, 15, -33, -79);
				background-color: transparent;
			}

		}
	}
}
