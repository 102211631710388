body{
    margin: 0;
}

a{
    text-decoration: none;
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

textarea{
  padding: 0;
}
