.renovaciones {
	float: left;
	width: 33%;
	
	@media (min-width: $medium-breakpoint) {
		width: 33%;
	}
	@media (max-width: $mobile-breakpoint) {
		width: 100%;
	}
	&__box {
		$url-renovaciones: $url-images + "banner_renovaciones2.jpg";
		background: url($url-renovaciones );
		background-size: cover;
		height: 400px;
		padding: 0 5%;

		@media (min-width: $medium-breakpoint) {
			padding: 0;
		}
		@media (max-width: $mobile-breakpoint) {
			height: 440px;
		}
		@media (max-width: '370px'){
            height: 410px;
        }
	}

	&__lead {
		@include rem(23);
		color: $renovacionColor;
		font-family: "Oswald_bold", sans-serif; //$renovacionLead;
		line-height: 1;
		padding: 50px 0 0 150px;
		text-align: right;
		width: 202px;
		margin-bottom: 55%;

		b {
			font-family: $renovacionLeadM;
		}

		@media (min-width: $tablet-breakpoint) and (max-width: $medium-breakpoint) {
			width: 100%;
			text-align: center;
			font-size: 2em;
		}
		@media (max-width: '370px'){
			padding: 50px 0 0 33%;
		}
	}

	&__btn {
		background: linear-gradient(to right,#7b2bda,#2e1ad3);
		margin: 5% auto;
		width: 90%;
		display: block;
		border: none;
		border-radius: 10px;
		height: 50px;
		padding: 3.5% 0;

		@media (max-width: $mobile-breakpoint){
			margin: 5% auto;
			width: 100%;		  
		}
	}

	&__llamanos-box {
		p {
			//@include rem(11);
			font-size: 0.9rem;
			color: $renovacionColor;
			font-family: $renovacionLeadB;
			line-height: 1;
			margin: 10px 20px;
			text-align: left;		  

			&.renovaciones__telefono {
				@include rem(17);
				font-family: $renovacionTelefono;
			}

			@media (min-width: $tablet-breakpoint) and (max-width: $medium-breakpoint) {

				font-size: 1.3em;
			}
			@media (min-width: '371px') and (max-width: $mobile-breakpoint){
				margin: 10px;
			}
		}
	}


}
