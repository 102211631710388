* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.not-mobile {
	@media (max-width: $mobile-breakpoint) {
		display: none;
	}
}

.not-desktop {
	@media (min-width: $medium-breakpoint) {
		display: none;
	}
}

.show-tablet {
	@media (max-width: $tablet-breakpoint) {
		display: none;
	}
}

.not-tablet {
	@media (min-width: $tablet-breakpoint) {
		display: none;
	}
}

.show-medium {
	@media (max-width: $medium-breakpoint) {
		display: none;
	}
}

.not-medium {
	@media (min-width: $medium-breakpoint) {
		display: none;
	}
}

.btn {
	@include rem(16);
	background: $botonBg1;
	border: 1px solid $botonBorde;
	box-sizing: border-box;
	color: $botonColor;
	cursor: pointer;
	float: left;
	font-family: $botonFuente;
	height: 40px;
	padding: 5px;
	width: 100%;

	@media (min-width: $mobile-breakpoint) {
		float: none;
		width: 260px;
	}

	&:hover {
		background: $botonHv1;
	}
}

h2 {
	@include rem(21);
	box-sizing: border-box;
	color: $tituloColor;
	font-family: $tituloFuente;
	line-height: 0;
	padding: 20px 0 13px 35px;
	text-align: left;
	width: 100%;
	text-transform: uppercase;

	@media (min-width: $mobile-breakpoint) {
		@include rem(31);
		padding-left: 0px;
	}
	@media (min-width: $tablet-breakpoint) and (max-width: $medium-breakpoint) {
		padding: 20px 0 13px 40px;
	}
}

.content {
	@include containerDesktop();
}

.caja {
	box-sizing: border-box;
	padding: 36% 5% 0 5%;
	@media (max-width: $medium-breakpoint) {
		padding: 25% 5% 5% 5%;
		
	}


	&__titulo {
		@include rem(25);
		color: $cajaColor;
		line-height: 1;
		padding-bottom: 30px;
		text-align: left;
    	text-shadow: 1px 3px 4px #555;
		width: 56%;

		span {
			display: inline-block;
			font-family: $cajaFuenteSpan;
		}

		b {
			display: inline-block;
			font-family: $cajaFuenteB;
			padding-right: 12px;
		}

		&::before {
			border-top: 1px solid $cajaBordeTop;
			box-sizing: border-box;
			// content: " ";
			display: block;
			padding-bottom: 7%;
			width: 28%;
		}
	}

	@media (min-width: $mobile-breakpoint) {
		padding: 16% 0px 0 5%;

		&__titulo {
			@include rem(65);
		}
	}
}
