.carousel {
	&__item {
		/*float: left;*/
		padding: 0 13px;
	}

	&-prev, &-next {
		display: none;
	}
}

.not-carousel {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 5% 5%;
}

section#carouselRevistas {

	margin: 0 auto;
	overflow: hidden;
	// width: 1170px;

	.revistas__box {
		padding: 0px;
		width: 265px;
	}

	.revista__btn {
		margin: 0 auto;
		width: 200px!important;
		height: 48px;
		padding: 4% 0;
		border: none;
		background: linear-gradient(to right,#7b2bda,#2e1ad3);
		border-radius: 10px;
	}

	.purejscarousel-dots-container {
		display: none;
	}

	.purejscarousel-btn-prev {
		@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
		background-color: transparent;
		border: none;
		cursor: pointer;
		float: left;
		transform: translateY(-350px) translateX(10px);

		&:hover {
			@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
			background-color: transparent;
			opacity: 0.4;
		}
	}

	.purejscarousel-btn-next {
		@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
		background-color: transparent;
		border: none;
		cursor: pointer;
		float: right;
		transform: scale(-1) translateY(350px) translateX(10px);
		&:hover {
			@include dibujaSprite(35, $sprite-logos, 19, -4, -83);
			background-color: transparent;

		}
	}
}
