.swipe {
	overflow: hidden;
	visibility: hidden;
	position: relative;
}

.swipe-wrap {
	overflow: hidden;
	position: relative;
}

.swipe-wrap > div {
	float: left;
	width: 100%;
	position: relative;
}


.container {
	max-width: 45em;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1em;
}

header,
footer {
	padding: 2em;
}

.list-unstyled {
	list-style: none outside none;

	li {
		display: inline;
	}

	li + li {
		&:before {
			content: ' • '
		}
	}
}

.demo-controls {
	text-align: center;
	padding: 1.5em;
}

// Swipe
.swipe-wrap > div {
	margin: 0;
	b {
		display: block;
		margin: 0 1.5em;
		padding: 4em 1em;
		font-size: 2em;
		text-align: center;
	}
}
